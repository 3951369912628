export const keys = {
	DISCOVER_ROUTE: 'discover.route.last',
	GLOBAL_SIDEBAR_PANEL: 'sidebar.global.panel',
	TINYMCE_PAGE_JUST_PUBLISHED: 'editor.tinyMCE.just.published',
	EDITOR_SURVEY_LAST_SUBMITTED: 'editor.survey.last.submitted',
	SUPERADMIN_SURVEY_REQUESTED: 'superadmin.survey.requested',
	SUPERADMIN_SURVEY_SUBMITTED: 'superadmin.survey.submitted',
	SUPERADMIN_SESSION_EXPIRED: 'superadmin.session.expired',
	RESTRICTED_PAGE_CONFIRMED: 'restricted.page.confirmed',
	HOME_ONBOARDING: 'home.onboarding',
	PAGE_JUST_CONVERTED: 'migration.page.just.converted',
	NAVIGATION_CLASSIC_OPT_OUT: 'navigation.classic.opt.out',
	NAVIGATION_HOME_OPT_OUT: 'navigation.home.opt.out',
	NAVIGATION_DEFAULT_OPTED_OUT: 'navigation.default.opted.out',
	NAVIGATION_OPT_IN_SEEN_SPOTLIGHT: 'navigation.opt.in.seen.spotlight',
	NAVIGATION_CLICKED_LOUD_OPT_IN_OR_VIEWED_FLAG: 'navigation.clicked.loud.opt.in.or.viewed.flag',
	NOTIFICATION_SPOTLIGHT_VIEWED: 'notification.spotlight.viewed',
	HOME_SECTION_COLLAPSED: 'home.section.collapsed',
	RECENTLY_WATCHED_SPACE: 'recentlyWatchedSpace',
	GROWTH_EXPERIMENT_REQUEST_UPGRADE_SENT: 'growth.experiment.request.upgrade.sent',
	GROWTH_EXPERIMENT_UPGRADE_DISMISSED_COUNT: 'growth.experiment.engagedUpgrade.dismissed.count',
	GROWTH_EXPERIMENT_UPGRADE_LAST_DISMISSED_DATE:
		'growth.experiment.engagedUpgrade.last.dismissed.date',
	LOCAL_STORAGE_DISPLAYS_NEXT_AT_KEY: 'growth.experiment.engagedBilling.displays.next.at',
	LOCAL_STORAGE_HAS_BILLING_DETAILS: 'growth.experiment.engagedBilling.has.billing.details',
	LOCAL_STORAGE_BILLING_API_NEXT_CALLED_AT_KEY:
		'growth.experiment.engagedBilling.billing.api.next.called.at',
	LOCAL_STORAGE_CONTENT_VIEW_CLOSEST_ELEMENT_SCROLL_POSITION:
		'ce.content.view.best.element.scroll.position',

	CONFLUENCE_SLACK_APP_IS_ENABLED: 'growth.experiment.confluence.slack-is-enabled',

	INVITE_ON_LINK_INSERT_DISMISSAL_COUNT:
		'growth.experiment.confluence.invite-on-link-insert-dismissal-count',
	INVITE_ON_LINK_INSERT_OPT_OUT: 'growth.experiment.confluence.invite-on-link-insert-opt-out',

	CROSS_JOIN_NUDGES_V3_VISITED_KEY: 'growth.experiment.confluence.cross-join-nudges-v3-visited',

	CROSS_JOIN_NUDGES_V3_VISITED_COUNT:
		'growth.experiment.confluence.cross-join-nudges-v3-visited-count',

	CROSS_JOIN_NUDGES_V3_USER_OPT_OUT: 'growth.experiment.confluence.cross-join-nudges-v3-opt-out',

	INVITE_ON_PAGE_PUBLISH_VISITED_KEY: 'growth.confluence.invite-on-page-publish-visited',
	INVITE_ON_PAGE_PUBLISH_DISMISSAL_COUNT:
		'growth.confluence.invite-on-page-publish-dismissal-count',
	INVITE_ON_PAGE_PUBLISH_OPT_OUT: 'growth.confluence.invite-on-page-publish-opt-out',

	/**
	 * The width of the right panel on Home
	 */
	HOME_SIDEBAR_WIDTH: 'home.sidebar.width',

	/**
	 * Used to store starred/favorited feature flags for the dev tools panel
	 */
	STARRED_FEATURE_FLAGS: 'confluence-frontend-next.starredFeatureFlags',

	/**
	 * Indicates that "Copy space permissions" (implemented by
	 * @confluence/copy-space-permissions) has completed i.e. space permissions
	 * have been copied.
	 */
	COPIED_SPACE_PERMISSIONS: 'copied.space.permissions',
	PERSIST_SCROLL_POSITION_IN_VIEW_PAGE: 'editor-persit-scroll-position',
	ID_START_CONTENT_IN_VIEW_PAGE: 'main-content',
	FABRIC_PAGE_OPTIN_MIGRATION_BANNER: 'fabric.page.optin.migration.banner.discovery.feature',
	FABRIC_TEMPLATE_MIGRATION_SUCCESS_FLAG: 'fabric-template-migration.success.flag',
	FABRIC_TEMPLATE_MIGRATION_ERROR_FLAG: 'fabric-template-migration.error.flag',
	OVERRIDES_STORAGE_KEY: 'confluence-frontend-next.featureFlagLocalOverrides',
	BYLINE_APPS_SHOW_ALL: 'byline.apps.show-all',
	SPACENAV_APPS_SHOW_ALL: 'spacenav.apps.show-all',
	HIDE_BULK_CONFIRMATION_DIALOG_LOCAL_STORAGE_KEY:
		'archive-pages.bulk-archive.confirmation-dialog.opt-out',
	ONBOARDING_STEP_INDEX: 'onboarding.account.setup.step.index',
	ONBOARDING_FORM_VALUES: 'onboarding.account.setup.form.values',
	ONBOARDING_QUICKSTART_TOUR_STATE: 'onboarding.quickstart.tour.state',
	RIGHT_SIDEBAR: 'right.sidebar',
	CONNECT_APP_ANALYTICS: 'ecosystem.confluence.connect.analytics',
	UPDATE_TEMPLATE_PROPERTIES_SET_ERROR: 'update.template.properties.set.error',
	/**
	 * Auto Convert TinyMCE pages
	 */
	EDITOR_REVERTED: 'autoconverted.pages.reverted',

	BLOGPOST_PERMISSIONS_NOTICE: 'blogpost.permissions.notice.error',
	QUERY_OVERRIDES: 'query.overrides',
	WHITEBOARD_BETA_ENABLED: 'whiteboard.beta.enabled',
	// Below are cc-automation keys for in-context discoverability messages
	AUTOMATION_DISCOVER_ARCHIVE_FROM_OVERFLOW_MENU: 'automation.discover.archive-from-overflow-menu',
	AUTOMATION_DISCOVER_ARCHIVE_FROM_HOVER_OVER_PAGE_TITLE:
		'automation.discover.archive-from-hover-over-page-title',
	AUTOMATION_DISCOVER_BULK_ARCHIVE: 'automation.discover.bulk-archive',
	AUTOMATION_DISCOVER_BULK_CHANGE_PAGE_OWNER: 'automation.discover.bulk-change-page-owner',
	AUTOMATION_DISCOVER_ADD_LABEL_TO_PAGE: 'automation.discover.add-label-to-page',
	AUTOMATION_DISCOVER_MEETING_NOTES_TEMPLATE: 'automation.discover.meeting-notes-template',
	AUTOMATION_DISCOVER_JIRA_LINK: 'automation.discover.jira-link',
	AUTOMATION_DISCOVER_MANUAL_TRIGGER: 'automation.discover.manual.trigger',
	AUTOMATION_DISCOVER_MANUAL_RULE_COUNT: 'automation.discover.manual.rule.count',
	AUTOMTION_DISCOVER_MANUAL_RULE_SMART_BUTTON: 'automation.discover.manual.rule.smart.button',
	AUTOMATION_DISCOVER_PAGE_PUBLIC_LINK: 'automation.discover.page-public-link',
	AUTOMATION_DISCOVER_GLOBAL_PUBLIC_LINK: 'automation.discover.global-public-link',
	AUTOMATION_DISCOVER_SPACE_ASSIGNED_BULK: 'automation.discover.space-assigned-bulk',
	AUTOMATION_DISCOVER_SPACE_ASSIGNED_INDIVIDUAL: 'automation.discover.space-assigned-individual',
	AUTOMATION_DISCOVER_GLOBAL_DEFAULT_SPACE: 'automation.discover.global-default-space',
	AUTOMATION_DISCOVER_VERIFIED_CONTENT: 'automation.discover.verified.content',
	AUTOMATION_DISCOVER_WHITE_BOARDS: 'automation.discover.publish.white.boards',
	AUTOMATION_DISCOVER_SHARE_SLACK: 'automation.discover.share.slack',
	AUTOMATION_DISCOVER_SET_CONTENT_STATE: 'automation.discover.set-content-state',
	SPACE_NAV_SECTIONS_EXPANDED_STATES: 'space-nav.sections.expand-states',
	SPACE_SETTINGS_NAV_SECTIONS_EXPANDED_STATES: 'space-settings-nav.sections.expand-states',
	SITE_SETTINGS_NAV_SECTIONS_EXPANDED_STATES: 'site-settings-nav.sections.expand-states',
	/**
	 * Comment Drafts
	 */
	COMMENT_DRAFTS_PAGE_CREATE: 'comments.drafts.page.create',
	COMMENT_DRAFTS_PAGE_EDIT: 'comments.drafts.page.edit',
	COMMENT_DRAFTS_PAGE_REPLY: 'comments.drafts.page.reply',
	COMMENT_DRAFTS_INLINE_CREATE: 'comments.drafts.inline.comments.create',
	COMMENT_DRAFTS_INLINE_EDIT: 'comments.drafts.inline.comments.edit',
	COMMENT_DRAFTS_INLINE_REPLY: 'comments.drafts.inline.reply',
	COMMENT_DRAFTS_EDITOR_INLINE_CREATE: 'comments.drafts.editor.inline.create',
	COMMENT_DRAFTS_EDITOR_INLINE_EDIT: 'comments.drafts.editor.inline.edit',
	COMMENT_DRAFTS_EDITOR_INLINE_REPLY: 'comments.drafts.editor.inline.reply',
	DATABASES_BETA_ENABLED: 'databases.beta.enabled',
	LOOM_BETA_ENABLED: 'loom.beta.enabled',
	BROWSER_BANNER_CLOSED_BY_USER: 'devloop.browser-support-warning.closed',
	HOME_FOLLOWING_FEED_SESSION_ID: 'home.follow-feed.sessionId',
	HOME_POPULAR_FEED_SESSION_ID: 'home.popular-feed.sessionId',

	/**
	 * Live Pages
	 */
	LIVE_PAGES_IN_PRODUCT_PROMPT_MODAL_LAST_DISMISSED:
		'live-pages.in-product-prompt.modal.last-dismissed',
	LIVE_PAGES_IN_PRODUCT_PROMPT_BANNER_SPACE_SETTINGS_LAST_DISMISSED:
		'live-pages.in-product-prompt.banner.space-settings.last-dismissed',
	LIVE_PAGES_IN_PRODUCT_PROMPT_BANNER_MISSION_CONTROL_LAST_DISMISSED:
		'live-pages.in-product-prompt.banner.mission-control.last-dismissed',

	// Bold new pages experiment
	UNREAD_PAGES_VISITED: 'bold-new-pages.experiment.unread-pages.visited',

	// Mention Reminders experiment
	MENTION_REMINDERS_SENT: 'mention-reminders.experiment.sent',

	// Nav 4 Site/Space settings back button URL
	NAV4_SETTINGS_BACK_BUTTON_URL: 'navigation.settings.back-button.url',

	// Recommend Standard During Premium EOT experiment
	RECOMMENDED_STANDARD_DURING_PREMIUM_EOT_EXPIRY_DATE:
		'recommended-standard-during-premium-eot.expiry-date',
};

export const PERSISTED_KEYS_ON_SERVER = {
	//keys stored on backend start with persisted
	PERSISTED_LEFT_SIDEBAR_WIDTH: 'persisted.left.sidebar.width',
	PERSISTED_LEFT_SIDEBAR_COLLAPSED: 'persisted.left.sidebar.collapsed',
	PERSISTED_GROWTH_PERSISTENT_UPGRADE_PRETRIAL_BANNER:
		'persisted.growth.persistent.upgrade.preTrial.banner',

	// The next time to show the External Collaboration Guest Satisfaction Survey to this guest user
	PERSISTED_GUEST_SURVEY_NEXT_TIME_TO_SHOW: 'persisted.guest.survey.next.time.to.show',

	// Overrided feature flags. These are persisted to the server so they can be
	// used when rendering in SSR
	PERSISTED_OVERRIDE_FEATURE_FLAGS: 'persisted.enterprise-experience.override.feature.flags',

	PERSISTED_ADMIN_ANNOUNCEMENT_BANNER: 'persisted.admin.announcement.banner',

	PERSISTED_ADMIN_ANNOUNCEMENT_BANNER_MIGRATOR_PROMPT:
		'persisted.admin.announcement.banner.migrator.prompt',

	PERSISTED_STORAGE_ENFORCEMENT_BANNER: 'persisted.storage.enforcement.banner',

	// Public Links Modals
	PERSISTED_RESTRICTIONS_DIALOG_PUBLIC_LINKS_MODAL:
		'persisted.restrictions.dialog.public.links.modal',
	PERSISTED_SHARE_DIALOG_PUBLIC_LINKS_TOGGLE_ON_MODAL:
		'persisted.share.dialog.public.links.toggle.on.modal',
	PERSISTED_SHARE_DIALOG_PUBLIC_LINKS_TOGGLE_OFF_MODAL:
		'persisted.share.dialog.public.links.toggle.off.modal',

	// Theming
	PERSISTED_USER_THEME_PREFERENCE: 'persisted.ecosystem.user.theme.preference',

	// Premium feature highlight
	PERSISTED_PREMIUM_FEATURE_HIGHLIGHT: 'persisted.premium.feature.highlight',

	// Reading Aids
	PERSISTED_READING_AIDS_AUTO_HIGHLIGHT: 'persisted.reading.aids.auto.highlight',

	// Bold new pages experiment
	PERSISTED_BOLD_NEW_PAGES_SATISFACTION_SURVEY_HAS_SEEN:
		'persisted.bold-new-pages.experiment.satisfaction-survey.has-seen',

	// Folder creation survey
	PERSISTED_CREATE_FOLDER_SATISFACTION_SURVEY_HAS_SEEN:
		'persisted.create-folder.satisfaction-survey.has-seen',

	// Page to Folder conversion banner
	PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED:
		'persisted.convert-page-to-folder.banner.dismissed',

	// Previously trialed premium users experiment
	PERSISTED_PREVIOUSLY_TRIALED_PREMIUM_USERS_DISMISSED:
		'persisted.previously.trialed.premium.users.banner.dismissed',

	// Quick Summary Experiment
	PERSISTED_QUICK_SUMMARY_REQUEST_SENT: 'persisted.growth.quick-summary-request-sent',

	// Experiment for Jira in Conflunce Apps
	// Growth: Trailblazer experiment TBLZ-1558 : go/trailblazerexperiments
	PERSISTED_JIRA_IN_APPS_DISMISSAL: 'persisted.jira.crossflow.jira-in-apps-ad.dismissal',

	// Experiment for Jira Project Board in Conflunce
	// Growth: Trailblazer experiment TBLZ-1289 : go/trailblazerexperiments
	PERSISTED_JIRA_AD_DISMISSAL: 'persisted.jira.crossflow.ad.dismissal',
	PERSISTED_JIRA_PROJECT_BOARD_TRIAL_REQUESTED:
		'persisted.jira.crossflow.jira-project-board.trial-requested',
	PERSISTED_JIRA_PROJECT_BOARD_ACCESS_REQUESTED:
		'persisted.jira.crossflow.jira-project-board.access-requested',

	// go/trailblazerexperimnents
	// TBLZ-1530: Crossflow from Confluence Tasks
	PERSISTED_TASKS_JIRA_AD_DISMISSAL:
		'persisted.jira.crossflow.confluence-tasks-to-jira.ad.dismissal',

	// Live Pages
	PERSISTED_LIVE_PAGES_FEEDBACK_BANNER_LAST_DISMISSED:
		'persisted.live-pages.feedback-banner.last-dismissed',

	//Team Calendar Dates on Page
	PERSISTED_TEAM_CALENDAR_DATES_ON_PAGE_HAS_SEEN: 'persisted.team-calendars.dates-on-page.has-seen',

	//Bite Sized Generic Premium Messages
	PERSISTED_BITE_SIZED_PREMIUM_MESSAGES_GENERIC_CADENCE:
		'persisted.bite-sized.premium-messages.generic.cadence',

	//Bite Sized Automation Premium Messages
	PERSISTED_BITE_SIZED_PREMIUM_MESSAGES_AUTOMATION_CADENCE:
		'persisted.bite-sized.premium-messages.automation.cadence',

	//Role Based Access Control
	PERSISTED_RBAC_TRACKING: 'persisted.rbac.tracking',

	// Loom cross_flow ad-control not interested preference
	PERSISTED_LOOM_CROSS_FLOW_AD_CONTROL_NOT_INTERESTED:
		'persisted.loom-cross-flow.ad-control.not-interested',

	// Status of exposure to a series of spotlight pulse outlines
	// highlighting Loom entrypoints to cross-sell-eligible customers
	PERSISTED_LOOM_CROSS_SELL_DISCOVERABILITY_PULSES_HAVE_ENDED:
		'persisted.loom-cross-sell.discoverability-pulses.have-ended',

	// Jira Cloud ID preference for site picker on Jira Issue Create
	PERSISTED_JIRA_CLOUD_ID_PREFERENCE: 'persisted.jira-cloud-id.preference',
};
