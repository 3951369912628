import { type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import { getIsCustomUI } from './getIsCustomUI';

export const extractTypeFromExtension = (extension: ForgeUIExtensionType) => {
	const { properties } = extension;

	if (properties.bodyType === 'rich-text') {
		return 'bodiedExtension';
	} else if (properties.layout === 'inline' && !getIsCustomUI(extension)) {
		return 'inlineExtension';
	}
	return 'extension';
};
